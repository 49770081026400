import * as React from "react";

import Hero from "@shared/hero";
import { Query } from "@graphql-types";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "@shared/layout";
import styled from "styled-components";
import { Container } from "@util/standard";
import { TABLET_BREAKPOINT } from "@util/constants";

const Container404 = styled(Container)`
  img {
    object-position: right;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    img {
      object-position: center;
    }
  }
`;

// markup
const NotFoundPage = () => {
  const { sanityNotFoundPage }: Query = useStaticQuery(graphql`
    {
      sanityNotFoundPage {
        hero {
          ...sanityHero
        }
      }
    }
  `);

  if (sanityNotFoundPage == null) {
    return null;
  }
  return (
    <Layout>
      <Container404>
        <Hero sanityHero={sanityNotFoundPage?.hero} height="auto" />
      </Container404>
    </Layout>
  );
};

export default NotFoundPage;
